import React, { Component } from 'react';
import classNames from 'classnames';

import icons from '../../images/icons.svg';
const ChevronDown = icons + '#chevron';

import FadeInLink from "../transitions/FadeInLink"

class Service extends Component {

  state = {
    open: false,
    change: false,
  };

  openService() {
    this.setState({ open: true, change: true });
  }

  render() {
    const serviceClass = classNames('Service border-medium-gray', {
      'Service--open': this.state.open,
      'Service--closed': !this.state.open,
      'Service--product': this.props.service.category === 'product' ? 'Service--product' : null,
      'show': this.state.change,
    });
    const serviceWrapperClass = classNames('Service__wrapper fadein', {
      'Service__wrapper--product': this.props.service.category === 'product' ? true : null,
    });
    const { name, intro, excerpt, description, technologies, icon, project, projectUrl, price, ctaText } = this.props.service;
    const serviceIcon = icons + '#' + icon;
    return (
      <div className={serviceWrapperClass}>
        <article className={serviceClass} onClick={ () => this.openService() }>
          <div className="Service__top border-bottom-medium-gray">
            <h3>{name}</h3>
            <p>{intro}</p>
            {technologies ? (
              <p>{technologies}</p>
            ) : (
              <p className="Service__price">{price} DKK</p>
            )}
            <div className="Service__icon">
              <svg>
                <use xlinkHref={serviceIcon}></use>
              </svg>
            </div>
          </div>
          <div className="Service__inner">
            <div className="Service__excerpt">
              <p className="has-small-font-size">{excerpt}</p>
            </div>
            {this.state.open ? (
              <>
                <div className="Service__description">
                  <p className="has-small-font-size">
                    <span dangerouslySetInnerHTML={description}></span>
                    {project ? (
                      <>
                        <br></br><br></br>Case study: <FadeInLink to={projectUrl} className="Link--nav" type="default">{project}</FadeInLink>
                      </>
                    ) : null}
                  </p>
                </div>
                <div className="Service__contact border-top-medium-gray">
                  <p className="has-small-font-size">{ctaText}<br></br> Feel free to <FadeInLink to="/contact" className="Link--nav" type="default">contact me</FadeInLink> for an initial talk.</p>
                </div>
              </>
            ) : (
              <div className="Service__readmore">
                <button className="Button Button--outline Button--icon" onClick={ () => this.openService() }>
                  <span>Read more</span>
                  <svg className="fill-black">
                    <use xlinkHref={ChevronDown}></use>
                  </svg>
                </button>
              </div>
            )}
          </div>
        </article>
      </div>
    )
  }

}

export default Service