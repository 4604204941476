const ServicesData = () => {

  const services = [
    {
      id: 1,
      name: 'Websites',
      category: 'service',
      intro: 'Custom design and frontend',
      excerpt: 'Good websites connect customers with brands, tell a story and are both beautiful and functional. They might even create delight.',
      description: {
        __html: "When starting a website it's important we're aligned on what we're trying to achieve. Once we understand the goal we can pick the right tools and technologies for the task. Great user experience and good perfomance should not be afterthoughts but something that is priotized and integrated from the start. Good foundational SEO, responsive design as well as accessibility are all important matters to take into account when building a website today.<br><br> I'm used to working close togheter with clients, designers and project managers, and I believe in involving all stakeholders as early in the process as possible to create the best results."
      },
      technologies: 'WordPress, Craft, SPA, JAMStack',
      ctaText: 'Looking start a website project?',
      icon: 'globe',
      project: 'Urbanhus',
      projectUrl: '/project/urbanhus'
    },
    {
      id: 2,
      name: 'Web apps',
      category: 'service',
      intro: 'Web application development',
      excerpt: 'Web applications can be complex but should be easy to use. They respect their users and make their lives easier.',
      description: {
        __html: "When creating tools such as platforms, booking systems and SaaS there's many moving parts. There is big results to be gained but also big risks. I believe projects should start with extensive user research and prototyping. Launches need to be quick and many in order to gain feedback from real users (humans). Code should be modular, perfomant and easy extendable. Interfaces need to be simple yet helpful and the user experience should even create joy if possible. <br><br> Weather working as part of a team or on an isolated project I always try to bring my knowledge of development, UX, design and even psychology to the table."
      },
      ctaText: 'Planning to build a web application?',
      technologies: 'React.js, Firebase, PWA',
      icon: 'gear',
      project: 'CareerMentor',
      projectUrl: '/project/careermentor'
    },
    {
      id: 3,
      name: 'Perfomance audit',
      category: 'product',
      intro: 'Optimizing your site can create fast and impactful results for your business',
      excerpt: 'Whether you want to boost revenues, improve conversion rates or improve retention and engagement, a well-optimised site is a key part.',
      description: {
        __html: "As countless <a href='https://wpostats.com/' target='_blank' rel='noopener noreferrer'>reports</a> have proven a poor perfoming site hurts everything from your conversion rate to SEO and oh yeah, the experience of your users. Even slight delays can have a profound effect.<br><br> With a perfomance audit I help you identify web perfomance issues and suggestions on how to solve them. I will start out with an audit digging into everything from infrastructure, network, assets, fonts, third party CDNs, rendering and runtime to find potential perfomance bottlenecks.<br><br> After the audit I will handover a report delivery displaying the results of the audit with suggested actions and expected outputs. I will guarentee you that your site will be faster by implementing the suggestions from the report which in turn will increase your metrics and decrease load time."
      },
      ctaText: 'Interesed in how to speed up your site?',
      price: '20,000',
      icon: 'spinner',
    },
    {
      id: 4,
      name: 'UX review',
      category: 'product',
      intro: 'Improve your KPIs and make your customers happy at the same time',
      excerpt: 'Improving the user experience of your product or website is a long game but you will often find immediate insights and actions from an UX review.',
      description: {
        __html: "Even if you have a strong brand and interested customers your website may be underperforming simply because it has a bad user experience. The issues creating a bad experience can be many. People dropping off during an overcomplicated checkout, trouble navigating the menu, finding information, a signup button hidden behind a stuck cookie notice, or bad text readability.<br><br> During a UX review I will test your website and use my knowledge to pinpoint less-than-perfect areas by going through things like user flows, content structure, navigation, accessibility, forms, CTAs as well as <a href='https://www.nngroup.com/articles/ten-usability-heuristics/' target='_blank' rel='noopener noreferrer'>usability heuristics</a>.<br><br> After the audit I will handover a report delivery displaying the results of the audit with suggested solutions, including screenshots and explanations of my findings. I will guarentee you that your site will have improved usability by implementing suggestions which in turn will increase your metrics and conversion rates."
      },
      ctaText: 'Interesed in how to improve your site?',
      price: '20,000',
      icon: 'ux',
    },
    // {
    //   id: 5,
    //   name: 'Strategy',
    //   category: 'product',
    //   intro: 'Vastly reduce the risk of a new project/product with a clear strategy',
    //   excerpt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur fuga nemo voluptate architecto nulla harum fugit nihil ratione modi earum commodi qui, corrupti, cupiditate debitis ipsam et.',
    //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias quae quam nostrum ipsam quos incidunt, fugiat tempore nobis est dignissimos adipisci. Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias quae quam nostrum ipsam quos incidunt, fugiat tempore nobis est dignissimos adipisci.',
    //   price: '25,000',
    //   icon: 'compass',
    // },
  ]

  return services;

}

export default ServicesData;