import React from "react"

import ServicesData from '../../data/services'
import Service from './Service';

const Services = () => {

  const data = ServicesData();

  return (
    <section className="Services outer-padding-x outer-padding-y">
      <div className="Services__intro max-width-big">
        <h1 className="fadein">Services</h1>
        <p className="has-big-font-size fadein">I help building value-adding digital web solutions through great ux, design and modern technologies.</p>
      </div>
      <div className="Services__inner">
        {data.map((service) =>
          service.category === 'service' ? <Service service={service} key={service.id} /> : null
        )}
      </div>

      <div className="Services__intro max-width">
        <h2 className="fadein">Products</h2>
        <p className="has-big-font-size fadein">Fixed scoped packages to a fixed price, no more no less.</p>
      </div>
      <div className="Services__inner">
        {data.map((service) =>
          service.category === 'product' ? <Service service={service} key={service.id} /> : null
        )}
      </div>
    </section>
  ) 

}

export default Services

