import React from "react"

import InnerLayout from "../components/InnerLayout"
import InnerWrapper from "../components/InnerWrapper"
import SEO from "../components/seo"
import Services from "../components/services/Services"

const ServicesPages = () => (
  <InnerLayout>
    <SEO title="Services"/>
    <InnerWrapper>
      <Services/>
    </InnerWrapper>
  </InnerLayout>
)

export default ServicesPages
